import {
  faChevronRight,
  faPaperPlane,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./Banner.css";

const Banner = (props) => {
  const path = useLocation();

  const firstPath = path.pathname.split("/")[1];
  const secondPath = path.pathname.split("/")[2];

  return (
    <React.Fragment>
      {!props.banner ? (
        <div
          className="banner"
          style={{
            backgroundImage: `
          url(${
            props.bkg || require("../../../Assets/Images/Back/Main/1.jpg")
          })`,
          }}
        >
          <div className="banner-content">
            <div className="banner-title">
              <h1>{props.sublink2 ? props.sublink2 : props.sublink}</h1>
              {/* <p>{props.slogan}</p> */}
              <div className="buttons">
                <Link to={props.newLink ? props.newLink : "/contact-us"}>
                  <button>
                    <FontAwesomeIcon icon={faPaperPlane} />
                    Book an Appointment
                  </button>
                </Link>
                <a href={"tel:1300360274"}>
                  <button className="call-banner">
                    <FontAwesomeIcon icon={faPhone} />
                    Call Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="breadcrumbs">
        <div className="subs">
          <Link to="/">
            <p>Home</p>
          </Link>
          <FontAwesomeIcon icon={faChevronRight} />
          <Link to={`/${firstPath}`}>
            <p
              className="activeC"
              style={props.sublink2 ? { color: "black" } : null}
            >
              {props.sublink}
            </p>
          </Link>
          {props.sublink2 ? <FontAwesomeIcon icon={faChevronRight} /> : null}
          <Link to={`/${firstPath}/${secondPath}`}>
            <p className="activeC">{props.sublink2}</p>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Banner;
