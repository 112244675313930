import "./Home.css";
import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import HomeComp from "./homeComp";
import Pickup from "./Pickup";
import NavMain from "../../common/navbar/NavMain";

const HExtra = React.lazy(() => import("./HExtra"));
const HMethods = React.lazy(() => import("./HMethods"));
const Htypes = React.lazy(() => import("./Htypes"));
const HChoose = React.lazy(() => import("./HChoose"));
const HProtect = React.lazy(() => import("./HProtect"));
const HFrequency = React.lazy(() => import("./HFrequency"));
const ContactComponent = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const Review = React.lazy(() => import("../../common/Review/Review"));
const Works = React.lazy(() => import("../../common/Works/Works"));

const Home = () => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      document.title =
        "Professional Carpet & Rug Cleaning Melbourne | Hire Today - purenbrightcleaning.com.au";
    }
  }, []);

  return (
    <>
      <Helmet>
        {/* <meta
          name="title"
          content="Professional Carpet & Rug Cleaning Melbourne | Hire Today - purenbrightcleaning.com.au"
        /> */}
        <meta
          name="description"
          content="We are professional rug & carpet cleaning company based in Melbourne - We offer cheap dry & steam carpet cleaning services in Pakenham & other Melbourne suburbs. Same day carpet cleaning services, Call 1300 360 274."
        />
        <meta
          name="keywords"
          content="rug cleaning melbourne, professional rug cleaning near me, rug cleaning near me, carpet cleaning near me melbourne"
        />
      </Helmet>

      <NavMain />

      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
      </Suspense>

      <HomeComp />

      <Pickup />

      <Suspense fallback={<div>Loading...</div>}>
        <HMethods />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <Htypes />
      </Suspense>

      <div className="review section">
        <Suspense fallback={<div>Loading...</div>}>
          <Review />
        </Suspense>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <HChoose />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HExtra />
      </Suspense>

      <div className="work section">
        <div className="work heading">
          <h2>Check Out Our Recent</h2>
        </div>
        <div className="work content">
          <Suspense fallback={<div>Loading...</div>}>
            <Works />
          </Suspense>
        </div>
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <HFrequency />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <HProtect />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <ContactComponent />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Home;
