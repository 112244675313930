import { Helmet } from "react-helmet";
import React, { Suspense, useEffect } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GallerySubComp = React.lazy(() => import("./GallerySubComp"));

const TilesGallery = () => {
  useEffect(() => {
    document.title =
      "Tile And Grout Cleaning Gallery Album - Pure N Bright Cleaning";
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Tile And Grout Cleaning Gallery Album - Checkout Our Recent Before / After Carpet Cleaning Work."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-sub-banner.jpg")}
          sublink="Gallery"
          sublink2="Tiles and Grout Cleaning Gallery"
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <GallerySubComp
          title="Tiles and Grout"
          images={[
            {
              src: require("../../../Assets/Images/Gallery/Tiles/1.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/2.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/3.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/4.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/5.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/6.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/7.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/9.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/10.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/11.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/12.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/13.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/14.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/15.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/16.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/17.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/18.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/19.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/20.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/21.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/22.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/23.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/24.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/25.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/26.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/27.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Tiles/28.jpg"),
            },
          ]}
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default TilesGallery;
