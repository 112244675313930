// import { Link } from "react-router-dom";

const HomeComp = () => {
  return (
    <>
      <div
        className="home"
        // style={{
        //   backgroundImage: `linear-gradient(90deg, rgba(71, 71, 71, 0.75), transparent), url(${require("../../../Assets/Images/Banner/home-banner.jpg")})`,
        //   backgroundPosition: "center",
        //   backgroundSize: "cover",
        //   backgroundRepeat: "no-repeat",
        // }}
      >
        <div className="main_banner">
          <div className="main_banner_content">
            <h1>
              PROFESSIONAL RUG <br /> CLEANING SERVICE <br /> MELBOURNE (All
              Suburbs)
            </h1>
            {/* <Link to={"/quote"}>
              <span>Book Appointment</span>

              <img
                src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                alt=""
              />
            </Link> */}
            <h2>Cleaning Method: Dry | Steam | Deep Cleaning | Hand Clean</h2>
            <h3>
              {" "}
              We clean Wool, Silk, Cowhide, Sheepskin, Persian and ALL TYPES OF
              RUGS
            </h3>
          </div>
        </div>
      </div>
      <div className="banner-strip">
        <h2>
          We clean Wool, Silk, Cowhide, Sheepskin, Persian and{" "}
          <span> ALL TYPES OF RUGS</span>
        </h2>
      </div>
    </>
  );
};

export default HomeComp;
