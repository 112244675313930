import { Link } from "react-router-dom";
import FAQComp from "../FAQ/FAQComp";

const Pickup = () => {
  return (
    <>
      <div className="faq">
        <div className="faq-heading">
          <h1>Frequently</h1>
          <h2>Asked Questions</h2>
        </div>
        <div className="lists">
          <FAQComp
            question="How much does it cost to clean a rug?"
            answer={
              <span style={{ fontSize: "inherit" }}>
                The cost depends on the size and condition of the rug. Reach out
                to us for a customized{" "}
                <Link to={"/quote"}>
                  <span style={{ color: "blue", fontSize: "inherit" }}>
                    quote
                  </span>{" "}
                </Link>
                based on your specific needs.
              </span>
            }
          />
          <FAQComp
            question="What types of rugs do you clean?"
            answer="We clean a wide variety of rugs, including oriental, Persian, wool, silk, and synthetic rugs."
          />

          <FAQComp
            question="How long does the cleaning process take?"
            answer="Typically, rug cleaning takes around 5-7 days, depending on the rug type and cleaning requirements."
          />

          <FAQComp
            question="Do you offer pickup and delivery services?"
            answer="Yes, we provide convenient pickup and delivery services for your rugs. Contact us to schedule a time that works best for you."
          />

          <FAQComp
            question="Can you remove stains and pet odors from my rug?"
            answer="Our professional cleaning services include stain and odor removal, using safe and effective techniques to refresh your rug."
          />

          <FAQComp
            question="Is the cleaning process safe for delicate or antique rugs?"
            answer="We use specialized methods tailored to delicate and antique rugs, ensuring they receive the care they need without damage."
          />
        </div>
      </div>
      <div className="pick_up section">
        <div className="pick_up heading">
          <h2>Free Pick Up And Drop Off Service - We Serve All Suburbs</h2>
        </div>
        <div className="pick_up content">
          <div className="pick_up info">
            <p>
              Rug Cleaning Melbourne - Cleaning the rugs using the DIY methods
              only cleans the surface, leaving behind a mix of soil and chemical
              residue. The best way to clean a rug is to get it cleaned
              professionally from the rug cleaning experts in order to achieve
              the best results. Whether your rug is a treasured Persian or a
              modern machine-made creation, the care we take when cleaning and
              sanitizing it, remains the same.
            </p>
            <p>
              Whenever we take on a new rug cleaning project, we comply with the
              guidelines. We complete a detailed analysis of the rug before we
              begin our job to decide the ideal approach to clean it. Generally,
              pre-vacuuming lifts loose dirt and it allows us to attack the
              deeper-rooted grime. In order to ensure that they are fully
              removed during the washing, localized stains and spots require
              special pre-treatment.
            </p>
          </div>
          <div className="pick_up img">
            <img
              loading="lazy"
              src={require("../../../Assets/Images/Dilivery/1.webp")}
              alt=""
            />
          </div>
        </div>
      </div>
      {/* <div className="lease-banner">
        <div className="wrapper">
          <div className="img">
            <img
              src={require("../../../Assets/Images/Icons/badge.png")}
              alt=""
            />
          </div>
          <div className="content">
            <h3>MOVING OUT?</h3>
            <h2>WORRIED ABOUT YOUR BOND?</h2>
            <p>
              - Our end of lease sofa cleaning gives 100% bond back guarantee.
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Pickup;
