import React from "react";
import { Suspense } from "react";
import { Link } from "react-router-dom";
import NavMain from "../../common/navbar/NavMain";
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));

function Test(props) {
  return (
    <div>
      <NavMain />

      <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
      </Suspense>

      <div
        className="home"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(71, 71, 71, 0.75), transparent), url(${require("../../../Assets/Images/Banner/home-banner.jpg")})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="main_banner">
          <div className="main_banner_content">
            <h1>
              PROFESSIONAL <br /> RUG CLEANING SERVICE <br />
              MELBOURNE
            </h1>
            <Link to={"/quote"}>
              <span>Book Appointment</span>

              <img
                src={require("../../../Assets/Images/Icons/enquire-blue.png")}
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Test;
