import "../../../index.css";
import "./FQuote.css";
import React, { Suspense, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import "@mui/material/styles";
import "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));

dayjs.extend(utc);
dayjs.extend(timezone);
const FQuote = () => {
  const [active, setActive] = useState("rug");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    delivery: "",
    count: 1,
    date: dayjs.tz(new Date(), "Australia/Sydney"),
  });

  function handleMessage(m) {
    setFormData((prevState) => ({
      ...prevState,
      message: `${formData.message + " " + m}`,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  function handleStateChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const handleDate = (newDate) => {
    setFormData((prevState) => ({
      ...prevState,
      date: newDate,
    }));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios("https://nodemailer.node.purenbrightcleaning.com.au/quote", {
        // await axios("http://localhost:7000/quote", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        data: formData,
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log(res.data);
            console.log("Message Sent");
            navigate("/thank-you");
            setLoading(false);
          } else if (res.status === 402) {
            console.log("Message failed to send");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Suspense fallback={<p>loading...</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading...</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading...</p>}>
        <Banner sublink="Free Quote" newLink="/quote" />
      </Suspense>
      <div className="fquote">
        <div className="heading">
          <h1>Book Appointment</h1>
        </div>
        <div className="info">
          <div className="info-head">
            <h2
              className={active === "rug" ? "head active" : "head"}
              onClick={() => setActive("rug")}
            >
              Rug
            </h2>
          </div>
          <div className="info-content">
            <form action="" onSubmit={handleSubmit}>
              <div className="size">
                <h3 className="main-heading">
                  How many {active === "rug" ? "rugs" : "carpets"} needs
                  cleaning?
                </h3>
                <div className="size-content">
                  <input
                    type="number"
                    name="count"
                    value={formData.count}
                    onChange={handleStateChange}
                    id=""
                  />
                </div>
              </div>
              <div className="about">
                <h3 className="main-heading">
                  Tell us about your {active === "rug" ? "Rug" : "Carpet"}
                </h3>
                <div className="buttons">
                  <button
                    type="button"
                    onClick={() => handleMessage("Food Stains")}
                  >
                    Food Stains
                  </button>
                  <button
                    type="button"
                    onClick={() => handleMessage("Pet Stains")}
                  >
                    Pet Stains
                  </button>
                  <button
                    type="button"
                    onClick={() => handleMessage("Moth Damage")}
                  >
                    Moth Damage
                  </button>
                </div>
                <div className="message">
                  <textarea
                    onChange={handleStateChange}
                    value={formData.message}
                    placeholder="Click any of the buttons above to add a custom reason"
                    name="message"
                    id=""
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <div className="delivery">
                <h3 className="main-heading">Cleaning Options</h3>
                <span>How will these rugs be handled?</span>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      name="delivery"
                      checked={formData.delivery === "Onsite Cleaning"}
                      value={"Onsite Cleaning"}
                      onChange={handleStateChange}
                      id=""
                    />
                    Onsite Cleaning
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="delivery"
                      checked={
                        formData.delivery === "Pickup & Drop Off Service"
                      }
                      value={"Pickup & Drop Off Service"}
                      onChange={handleStateChange}
                      id=""
                    />
                    Pickup & Drop Off Service
                  </label>
                </div>
                {/* <hr /> */}
              </div>
              <div className="appointment">
                <h3 className="main-heading">Make an Appointment</h3>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DesktopDateTimePicker"]}>
                    <DemoItem>
                      <DateTimePicker
                        className="font"
                        onChange={handleDate}
                        value={formData.date}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="contact">
                <h3 className="main-heading">Contact Details</h3>
                <div className="contact-form">
                  <label>
                    <span>Name</span>
                    <input
                      onChange={handleStateChange}
                      value={formData.name}
                      type="text"
                      name="name"
                      id=""
                    />
                  </label>
                  <label>
                    <span>Email</span>
                    <input
                      onChange={handleStateChange}
                      value={formData.email}
                      type="email"
                      name="email"
                      id=""
                    />
                  </label>
                  <label>
                    <span>Mobile</span>
                    <input
                      onChange={handleStateChange}
                      value={formData.phone}
                      type="number"
                      name="phone"
                      id=""
                    />
                  </label>
                  <label>
                    <span>Address</span>
                    <input
                      onChange={handleStateChange}
                      value={formData.address}
                      type="text"
                      name="address"
                      id=""
                    />
                  </label>
                </div>
              </div>
              <div className="submit">
                {loading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <button type="submit" onClick={submitForm}>
                    SUBMIT
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      <Suspense fallback={<p>loading...</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading...</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default FQuote;
