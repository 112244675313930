import { Helmet } from "react-helmet";
import React, { Suspense, useEffect } from "react";
const Banner = React.lazy(() => import("../../common/Banner/Banner"));
const Footer = React.lazy(() => import("../../common/Footer/Footer"));
const Navbar = React.lazy(() => import("../../common/navbar/navbar"));
const ContactComp = React.lazy(() =>
  import("../../common/Contact/ContactComponent")
);
const NavMain = React.lazy(() => import("../../common/navbar/NavMain"));
const GallerySubComp = React.lazy(() => import("./GallerySubComp"));

const UpholsteryGallery = () => {
  useEffect(() => {
    document.title =
      "Upholstery Cleaning Gallery Album - Pure N Bright Cleaning";
  }, []);
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Upholstery cleaning gallery album - Checkout Our Recent Before / After Carpet Cleaning Work."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <Suspense fallback={<p>loading..</p>}>
        <Navbar />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Banner
          img={require("../../../Assets/Images/Banner/gallery-sub-banner.jpg")}
          sublink="Gallery"
          sublink2="Upholstery Cleaning Gallery"
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <GallerySubComp
          title="Upholstery"
          images={[
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/1.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/2.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/3.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/4.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/5.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/6.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/7.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/8.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/9.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/10.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/11.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/12.jpg"),
            },
            {
              src: require("../../../Assets/Images/Gallery/Upholstery/13.jpg"),
            },
          ]}
        />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <ContactComp />
      </Suspense>
      <Suspense fallback={<p>loading..</p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default UpholsteryGallery;
