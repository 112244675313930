import axios from "axios";
import React, { useState } from "react";
import Banner from "../../common/Banner/Banner";
import Footer from "../../common/Footer/Footer";
import Navbar from "../../common/navbar/navbar";
import NavMain from "../../common/navbar/NavMain";
import "./Feedback.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

function Feedback(props) {
  const [feedback, setFeedback] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFeedback = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(feedback);
    try {
      await axios
        .post("https://nodemailer.node.purenbrightcleaning.com.au/feedback", {
          feedback: feedback,
        })
        .then((res) => {
          console.log(res.data);
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Navbar />
      <NavMain />
      <Banner sublink="Feedback" />
      <div className="feedback">
        <div className="heading">
          <h2>Write a Feedback...</h2>
        </div>
        <div className="form">
          <form onSubmit={handleFeedback}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              name="feedback"
              type="text"
              placeholder="Feedback."
            />

            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <input type="submit" />
            )}
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Feedback;
