import {
  faChevronRight,
  // faMousePointer,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense } from "react";
import { Link, useLocation } from "react-router-dom";
import FabricLanding from "../../../common/Contact/FabricLanding";
// import LandingContact from "../../../common/Contact/LandingContact";
import Quote from "../../../common/Contact/Quote";
import Footer from "../../../common/Footer/Footer";
import Navbar from "../../../common/navbar/navbar";
import NavMain from "../../../common/navbar/NavMain";
import "./Pressure.css";

function PressureCleaning(props) {
  const { pathname } = useLocation();

  // let CR = pathname.split("-")[0];
  let cleaning = pathname.split("-")[1];
  return (
    <div>
      <Suspense fallback={<div>Loading..</div>}>
        <NavMain />
      </Suspense>
      <Suspense fallback={<div>Loading..</div>}>
        <Navbar />
      </Suspense>
      <div
        className="homeL"
        style={{
          backgroundImage: `linear-gradient(90deg, rgba(85, 85, 85, 0.5), transparent), url(${require("../../../../Assets/Images/Banner/pressure3.jpg")})`,
        }}
      >
        <div className="main_banner">
          <div className="main_banner_content">
            <h1>
              <span className="title"> PRESSURE CLEANING SERVICE</span> <br />
              MELBOURNE (ALL SUBURBS)
            </h1>

            <Link to={"/contact-us"} className="rug-contact">
              <span>Enquire Now </span>

              <img
                src={require("../../../../Assets/Images/Icons/enquire-blue.png")}
                alt=""
              />
            </Link>
          </div>
          <div className="quote">
            <Suspense fallback={<div>Loading..</div>}>
              <Quote />
            </Suspense>
          </div>
        </div>

        <Suspense fallback={<div>Loading..</div>}>
          <FabricLanding cleaning={cleaning} CR={"pressure"} />
        </Suspense>

        <div className="tube section">
          <div className="tube">
            <div className="pressure-vid">
              <h2>Our Pressure Cleaning Process</h2>
              <iframe
                title="Youtube"
                srcdoc={`
                <style>
                body, .full {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    object-fit: cover;
                }
            </style>
            <a
                href='https://www.youtube.com/embed/IBkj8G8dOOM?si=pKgAcy4pA32C4ha4&amp;controls=0'
                class='full'
            >
                <img
                    src=${require("../../../../Assets/Images/Banner/pressure-thumbnail.jpg")}
                    class='full'
                />
                <svg
                    version='1.1'
                    viewBox='0 0 68 48'
                    width='68px'
                    style='position: relative;'
                >
                    <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                    <path d='M 45,24 27,14 27,34' fill='#fff'></path>
                </svg>
            </a>
                `}
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="choose_us section">
          <div className="choose_us heading">
            <h2>Why Choose Us?</h2>
          </div>
          <div className="choose_us content">
            <div className="choose_us info">
              <ul>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/sheild.png")}
                    alt=""
                  />{" "}
                  DAMAGE FREE SERVICE
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/check.png")}
                    alt=""
                  />{" "}
                  QUALITY SERVICES
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/dollar.png")}
                    alt=""
                  />{" "}
                  ECONOMICAL
                </li>
              </ul>
              <ul>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/sanitization.png")}
                    alt=""
                  />{" "}
                  SANITIZATION & DISINFECTION
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/health.png")}
                    alt=""
                  />{" "}
                  HEALTHY ENVIRONMENT
                </li>
                <li>
                  <img
                    src={require("../../../../Assets/Images/Icons/plant.png")}
                    alt=""
                  />{" "}
                  NEW LOOK
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="work section">
          <div className="work heading">
            <h2>Check Out Our Recent</h2>
          </div>
          <div className="work content">
            {" "}
            <section className="worksD">
              <h1 className="heading">Before & After Pressure Cleaning Work</h1>
              <div className="works-content">
                <img
                  src={require("../../../../Assets/Images/Gallery/Pressure/2.jpeg")}
                  alt=""
                />
                <img
                  src={require("../../../../Assets/Images/Gallery/Pressure/1.jpeg")}
                  alt=""
                />
                {/* <img
                  src={require("../../../../Assets/Images/Gallery/Leather/12.jpg")}
                  alt=""
                /> */}
              </div>
              <Link to={"/gallery"}>
                <div className="more-images">
                  <h1>FOR MORE IMAGES CHECK OUT OUR GALLERY</h1>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </Link>
            </section>
          </div>
        </div>

        <div className="review section">
          <section className="reviews">
            <div className="heading">
              <h1> WHAT OUR CUSTOMERS HAVE TO SAY </h1>
            </div>
            <div className="review-content">
              <div className="content-box">
                <p>
                  Deck and driveway look brand new after the pressure cleaning,
                  which exceeded my expectations given how dirty they were. The
                  entire process was smooth, from the efficient scheduling to
                  the thorough cleanup.
                </p>
                <span>Cassie</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  I had my driveway pressure cleaned and was extremely satisfied
                  with the results. The team arrived on time, and the driveway
                  now looks spotless and free of any stains. I'll definitely be
                  using this service again.
                </p>
                <span>Phoebe</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
              <div className="content-box">
                <p>
                  Just had my patio pressure cleaned, and the result is amazing.
                  The concrete had tough stains that I couldn’t stand any
                  longer. Now it looks spotless and refreshed. The service was
                  excellent too.
                </p>
                <span>Afinial</span>
                <div className="icons">
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                  <FontAwesomeIcon icon={faStar} />
                </div>
              </div>
            </div>
            <Link
              to={"https://www.productreview.com.au/listings/pure-n-bright"}
            >
              <div className="more-review">
                <span>For More Reviews Click Here</span>
              </div>
            </Link>
          </section>
        </div>

        <div className="extra">
          <div className="extra1">
            <img
              src={require("../../../../Assets/Images/Icons/satisfaction.png")}
              alt=""
            />
            <h2>Satisfaction Guarantee</h2>
            <p>
              We are dedicated to revitalizing your outdoor spaces and go above
              and beyond to exceed our customers' expectations. Let our pressure
              cleaning experts restore the beauty of your surfaces and deliver
              the spotless shine they deserve.
            </p>
          </div>
        </div>

        <div className="threeD">
          <div className="wrapper">
            <div className="container">
              <div className="comment-box">
                <img
                  src={require("../../../../Assets/Images/Banner/comment-box.webp")}
                  alt=""
                />
              </div>
              <div className="comment-info">
                <h1>Let’s Shine!</h1>
                <p>
                  Eliminate grime and stubborn stains from your outdoor surfaces
                  with top-notch pressure cleaning services in Melbourne.
                </p>

                <Link to={"/contact-us"}>
                  <button className="submit">
                    <span>Enquire Now</span>
                    <img
                      src={require("../../../../Assets/Images/Icons/enquire-blue.png")}
                      alt=""
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Suspense fallback={<div>Loading..</div>}>
          <Footer />
        </Suspense>
      </div>
    </div>
  );
}

export default PressureCleaning;
